import styled from '@emotion/styled'

// Library
import Container from 'library/Container'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    flex-direction: row;
    align-content: center;
  }
`

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  padding: 1rem 1rem 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    align-items: center;

    padding: 1rem 1.777rem 1.5rem;
  }
`
const Title = styled.h1`
  letter-spacing: 2.18px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: 74%;

    letter-spacing: -5.2px;
    font-size: 10.4vw;
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    font-size: 8.666666666666667rem;
  }
`

export { Wrapper, FlexContainer, Title }
