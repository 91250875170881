import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/react'

// Keyframes
const move = keyframes`
  0% {
    width: 0;
    height: 0;
  }
  
  50% {
    width: 100%;
    height: 100%;
  }
  
  100% {
    width: 100%;
    height: 100%;
  }
`

const reverseMove = keyframes`
  0% {
    width: 100%;
    height: 100%;

    border-width: 2px;
  }
  
  50% {
    width: 100%;
    height: 100%;

    border-width: 2px;
  }
  
  100% {
    width:0%;
    height: 0%;

    border-width: 2px;
  }
`

// Border
const rightCardBorder = (theme, dark) => css`
  &::after {
    top: calc(0% - 2px);
    right: calc(0% - 2px);
  }

  &::before {
    bottom: calc(0% - 2px);
    left: calc(0% - 2px);
  }

  &.active {
    border: 2px solid red;
    border-color: ${dark
      ? theme.colors.primaryDark
      : theme.colors.primaryLightest};
    border-top-color: ${dark ? theme.colors.primaryDarkestAlt : '#FFF'};
    border-right-color: ${dark ? theme.colors.primaryDarkestAlt : '#FFF'};

    &::after {
      border: 2px solid transparent;
      border-top-color: ${dark
        ? theme.colors.primaryDark
        : theme.colors.primaryLightest};
      border-right-color: ${dark
        ? theme.colors.primaryDark
        : theme.colors.primaryLightest};
    }

    &::before {
      border: 0 solid;
      border-color: ${dark ? theme.colors.primaryDarkestAlt : '#FFF'};
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
`

const leftCardBorder = (theme, dark) => css`
  &::after {
    top: calc(0% - 2px);
    left: calc(0% - 2px);
  }

  &::before {
    bottom: calc(0% - 2px);
    right: calc(0% - 2px);
  }

  &.active {
    border: 2px solid;
    border-color: ${dark
      ? theme.colors.primaryDark
      : theme.colors.primaryLightest};
    border-top-color: ${dark ? theme.colors.primaryDarkestAlt : '#FFF'};
    border-left-color: ${dark ? theme.colors.primaryDarkestAlt : '#FFF'};

    &::after {
      border: 2px solid transparent;
      border-top-color: ${dark
        ? theme.colors.primaryDark
        : theme.colors.primaryLightest};
      border-left-color: ${dark
        ? theme.colors.primaryDark
        : theme.colors.primaryLightest};

      animation: ${move} 1.5s linear;
    }

    &::before {
      border: 0 solid;
      border-color: ${dark ? theme.colors.primaryDarkestAlt : '#FFF'};
      border-top-color: transparent;
      border-left-color: transparent;

      animation: ${reverseMove} 1.5s linear;
    }
  }
`

let dynamicStyles = ({ theme, dark, reverse }) =>
  reverse ? rightCardBorder(theme, dark) : leftCardBorder(theme, dark)

// Styles
const Title = styled.h2`
  color: ${({ theme, dark }) =>
    dark ? theme.colors.primaryLightest : theme.colors.primaryDark};

  opacity: 0;
  transform: translate(0, 100%);
  transition: all 0.5s ease 0.5s;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font: ${({ theme }) => theme.typography.h2};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 5vw;
    line-height: 4.444444444444444vw;
    color: ${({ theme, dark }) =>
      dark ? theme.colors.primaryDark : theme.colors.primaryLightest};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    font: ${({ theme }) => theme.typography.h2Desk};
    line-height: 64px;
  }
`

const Desc = styled.p`
  margin-top: 0.375rem;

  letter-spacing: 0.82px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
  color: ${({ theme, dark }) =>
    dark ? '#FFF' : theme.colors.primaryDarkestAlt};

  opacity: 0;
  transform: translate(0, 100%);
  transition: all 0.5s ease 1s;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: -1.597222222222222vw;

    font-size: 1.25vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    margin-top: -1.222222222222222rem;

    font-size: 1rem;
  }
`

let Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: calc(100% - 1rem);
  margin: 3rem 0 0 1rem;

  &:first-of-type {
    margin-top: 1.777777777777778rem;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
  }

  &::after {
    width: 100%;
    height: 100%;
  }

  &::before {
    width: 0;
    height: 0;
  }

  // Animation
  &.active {
    img,
    ${Title}, ${Desc} {
      opacity: 1;
      transform: translate(0);
    }

    &::after {
      animation: ${move} 1.5s linear;
    }

    &::before {
      animation: ${reverseMove} 1.5s linear;
    }

    ${dynamicStyles}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    margin: ${props =>
      props.reverse
        ? '8.333333333333333vw 2.222222222222222vw 0 0'
        : '8.333333333333333vw 0 0 2.222222222222222vw'};

    padding-bottom: 1.777777777777778rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    margin: ${props =>
      props.reverse
        ? '6.666666666666667rem 1.777777777777778rem 0 0'
        : '6.666666666666667rem 0 0 1.777777777777778rem'};
  }
`

const Figure = styled.figure`
  height: 50vw;
  max-height: 450px;
  width: 100%;
  margin: calc(-1rem - 2px) 0 0 calc(-1rem - 2px);

  overflow: hidden;
  z-index: 1;

  img {
    height: 50vw;
    max-height: 450px;

    object-fit: cover;
    object-position: center;

    opacity: 0;
    transform: translate(0, 110%);
    transition: all 0.5s ease-in-out;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 65%;
    margin: ${props =>
      props.reverse
        ? 'calc(-2.222222222222222vw - 2px) calc(-2.222222222222222vw - 2px) 0 0'
        : 'calc(-2.222222222222222vw - 2px) 0 0 calc(-2.222222222222222vw - 2px)'};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    margin: ${props =>
      props.reverse
        ? 'calc(-1.777777777777778rem - 2px) calc(-1.777777777777778rem - 2px) 0 0'
        : 'calc(-1.777777777777778rem - 2px) 0 0 calc(-1.777777777777778rem - 2px)'};
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 464px;
  width: 100%;
  padding: 0.5rem 1rem 0.625rem 0.75rem;

  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: flex-end;

    width: ${({ inSkeleton }) => (inSkeleton ? '100%' : 'auto')};
    padding: 0
      ${props =>
        props.reverse ? '1.666666666666667vw' : '1.944444444444444vw'}
      2.222222222222222vw 2.222222222222222vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    padding: 0
      ${props =>
        props.reverse ? '1.555555555555556rem' : '1.333333333333333rem'}
      1.777777777777778rem 1.777777777777778rem;
  }
`

// Skeletons
const ImageSkeleton = styled.div`
  width: 100%;
  height: 50vw;
  max-height: 450px;
  margin: calc(-1rem - 2px) 0 0 calc(-1rem - 2px);

  background: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 65%;
    margin: ${props =>
      props.reverse
        ? 'calc(-2.222222222222222vw - 2px) calc(-2.222222222222222vw - 2px) 0 0'
        : 'calc(-2.222222222222222vw - 2px) 0 0 calc(-2.222222222222222vw - 2px)'};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    margin: ${props =>
      props.reverse
        ? 'calc(-1.777777777777778rem - 2px) calc(-1.777777777777778rem - 2px) 0 0'
        : 'calc(-1.777777777777778rem - 2px) 0 0 calc(-1.777777777777778rem - 2px)'};
  }
`

const TitleSkeleton = styled.div`
  width: 70%;
  height: 1.5rem;
  margin-bottom: 0.5rem;

  background: ${({ theme, dark }) =>
    dark ? theme.colors.primaryLightest : theme.colors.primaryDark};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 4rem;
  }
`

const DescSkeleton = styled.div`
  width: ${({ size }) => size};
  height: 1.125rem;
  margin-bottom: 0.25rem;

  background: ${({ theme, dark }) =>
    dark ? '#FFF' : theme.colors.primaryDarkestAlt};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 1.25vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    height: 1rem;
  }
`

export {
  ImageSkeleton,
  TitleSkeleton,
  DescSkeleton,
  Wrapper,
  Figure,
  Info,
  Title,
  Desc
}
