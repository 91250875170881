import { useEffect, useMemo, useRef, useState } from 'react'

const transparentImage =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

export const GlobalLazyloadStyles = () => {
  return (
    <noscript>
      <style>{`img.sl-lazyload{display: none;}`}</style>
    </noscript>
  )
}

const ResponsiveImage = ({
  className,
  image,
  sizes,
  alt = image.alt,
  variation = 'optimized',
  background
}) => {
  const imageRef = useRef(null)

  // When true, the image may load normally
  const [canLoad, setCanLoad] = useState(false)

  // When true, the image has been successfully loaded by the browser
  const [hasLoaded, setHasLoaded] = useState(false)

  // Get the optimized variation if available
  const source = useMemo(() => {
    // If the given image was a string, simply return it
    if (typeof image === 'string' || !image.files) return

    const chosenVariation = image.files.find(
      file => file.variation === variation
    )

    return chosenVariation
      ? chosenVariation
      : image.files.find(file => file.variation === 'original')
  }, [image])

  // Create a srcset
  const sourceSet = useMemo(() => {
    if (!source) return

    const sets = []
    const large = image.files.find(file => file.variation === 'large')
    const medium = image.files.find(file => file.variation === 'medium')
    const thumbnail = image.files.find(file => file.variation === 'thumbnail')

    if (thumbnail)
      sets.push(`${encodeURI(thumbnail.path)} ${thumbnail.meta.width}w`)
    if (medium) sets.push(`${encodeURI(medium.path)} 600w`)
    if (large) sets.push(`${encodeURI(large.path)} 1200w`)
    sets.push(`${encodeURI(source.path)} ${source.meta.width}w`)

    return sets.join(',')
  }, [source])

  const imageStyle = useMemo(() => {
    return {
      backgroundColor:
        background || hasLoaded ? 'transparent' : `#${source.background_color}`
    }
  }, [background, source, hasLoaded])

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].intersectionRatio > 0) {
        // Image entered the viewport, set it as loadable
        setCanLoad(true)

        // Stop observing the image
        observer.unobserve(imageRef.current)
      }
    })

    const loadHandler = () => {
      // After loading the image, the "hasLoaded" flag is used to hide the background color
      setHasLoaded(true)
    }

    observer.observe(imageRef.current)
    imageRef.current.addEventListener('load', loadHandler)

    return () => {
      observer.unobserve(imageRef.current)
      imageRef.current.removeEventListener('load', loadHandler)
    }
  }, [source])

  return (
    <>
      <img
        ref={imageRef}
        className={'sl-lazyload ' + className}
        style={imageStyle}
        src={canLoad ? (source && source.path) || image : transparentImage}
        srcSet={canLoad ? (sourceSet ? sourceSet : undefined) : undefined}
        sizes={canLoad ? (sourceSet ? sizes : undefined) : undefined}
        alt={alt}
      />
      <noscript>
        <img src={(source && source.path) || image} alt={alt} />
      </noscript>
    </>
  )
}

export default ResponsiveImage
