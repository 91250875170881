import PropTypes from 'prop-types'

// Components
import Card from './Card'

// Styles
import { Wrapper, FlexContainer } from './style.js'

const Projects = ({ projects, dark }) => (
  <Wrapper>
    <FlexContainer>
      {projects?.data
        ? projects.data.map((e, index) => {
            const { desc, card_image } = e.data
            const { title, slug } = e

            return (
              <Card
                key={index}
                title={title}
                desc={desc}
                slug={slug}
                dark={dark}
                reverse={index % 2}
                card_image={card_image}
              />
            )
          })
        : [1, 2, 3].map((e, index) => (
            <Card key={index} dark={dark} reverse={index % 2} />
          ))}
    </FlexContainer>
  </Wrapper>
)

Projects.propTypes = {
  dark: PropTypes.bool
}

Projects.defaultProps = {
  dark: false
}

export default Projects
