import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import 'intersection-observer'
import Observer from '@researchgate/react-intersection-observer'

// Components
import ResponsiveImage from 'components/ResponsiveImage'

// Styles
import theme from 'styles/theme.js'
import {
  ImageSkeleton,
  TitleSkeleton,
  DescSkeleton,
  Wrapper,
  Figure,
  Info,
  Title,
  Desc
} from './style.js'

const Card = ({ title, desc, card_image, slug, dark, reverse }) => {
  const [isAnimationStarted, setIsAnimationStarted] = useState(false)

  const handleIntersection = useCallback(
    event => {
      if (!isAnimationStarted && event.isIntersecting)
        setIsAnimationStarted(true)
    },
    [isAnimationStarted]
  )

  const options = {
    onChange: handleIntersection,
    rootMargin: '0px 0px -200px 0px'
  }

  return title ? (
    <Observer {...options}>
      <Wrapper
        dark={dark}
        reverse={reverse}
        className={isAnimationStarted ? 'active' : ''}
      >
        <Figure reverse={reverse}>
          {card_image && (
            <Link
              href={`/projetos/[project]?slug=${slug}`}
              as={`/projetos/${slug}`}
              passHref
            >
              <a title={title}>
                <ResponsiveImage
                  image={card_image}
                  alt={title}
                  sizes={`
                    (max-width: ${theme.breakpoints.tablet - 1}px) 100vw,
                    (max-width: 1248px) 44vw,
                    774px
                  `}
                />
              </a>
            </Link>
          )}
        </Figure>
        <Info>
          <Title dark={dark}>{title}</Title>
          <Desc dark={dark}>{desc}</Desc>
        </Info>
      </Wrapper>
    </Observer>
  ) : (
    <Wrapper dark={dark} reverse={reverse}>
      <ImageSkeleton />
      <Info inSkeleton>
        <TitleSkeleton dark={dark} />
        <DescSkeleton size="95%" dark={dark} />
        <DescSkeleton size="95%" dark={dark} />
        <DescSkeleton size="70%" dark={dark} />
      </Info>
    </Wrapper>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  slug: PropTypes.string,
  dark: PropTypes.bool,
  reverse: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

Card.defaultProps = {
  dark: false,
  reverse: false
}

export default Card
